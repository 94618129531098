import React from 'react'
import { graphql } from 'gatsby'
import { Box } from 'rebass'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Container from '../../components/container'
import Content from '../../components/content'
import Scrolltabs from '../../components/scrolltabs'
import Pagetitle from '../../components/pagetitle'

const ConsulenzaPage = ({ data }) => {
  const { frontmatter, html, headings } = data.allMarkdownRemark.edges[0].node
  const { title } = frontmatter

  // We add the id to all H1s and use them for the top menu links
  const H1s = /(<h1)(>([^\s]+|[a-zA-Z0-9_ ]+)<\/h1>)/g
  const newHTML = html.replace(H1s, (_, a, b, c) => {
    const slug = c
      .split(' ')
      .join('')
      .toLowerCase()

    return `${a} id="${slug}"${b}`
  })

  const titles = headings.filter(h => h.depth === 1)
  const links = titles.map(title => ({
    title: title.value,
    link: `#${title.value
      .split(' ')
      .join('')
      .toLowerCase()}`,
  }))

  return (
    <Layout>
      <SEO title={title} />
      <Pagetitle>{title}</Pagetitle>
      <Scrolltabs links={links} />
      <Container>
        <Box pb={6}>
          <Content dangerouslySetInnerHTML={{ __html: newHTML }} />
        </Box>
      </Container>
    </Layout>
  )
}

export const consulenzaPageQuery = graphql`
  query ConsulenzaPage {
    allMarkdownRemark(
      filter: { fields: { slug: { eq: "/servizi/consulenza/" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          headings {
            value
            depth
          }
          html
        }
      }
    }
  }
`

export default ConsulenzaPage
