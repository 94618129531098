import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const Menu = styled.ul`
  text-align: center;
  padding: 16px;
  border-bottom: 1px solid #f3f3f3;
  background: #fff;

  position: sticky;
  top: 0;

  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    display: inline-block;
    margin: 0 16px;
  }
`

const Scrolltabs = ({ links }) => {
  /* 
    It expects an array of objects with the following properties:
    [{
        title: String
        link: String
    }]
  */
  //if (links.length < 0) return

  const [linksWithPosition, setLinks] = useState(links)

  useEffect(() => {
    const newLinks = links.map(item => ({
      ...item,
      position:
        document.getElementById(item.link.replace(/#/g, '')).offsetTop - 64,
    }))
    setLinks(newLinks)
    window.addEventListener('scroll', handleWindowScroll)
    return () => window.removeEventListener('scroll', handleWindowScroll)
  }, [links])

  const [active, setActive] = useState('')
  const handleWindowScroll = () => {
    const currentPosition = document.documentElement.scrollTop + 64
    setActive(currentPosition)
  }

  const handleScrollToElement = (event, position) => {
    event.preventDefault()
    window.scrollTo(0, position)
  }

  const isActiveLink = link => {
    const index = linksWithPosition.indexOf(link)

    if (index < linksWithPosition.length - 1) {
      return (
        active >= linksWithPosition[index].position &&
        active < linksWithPosition[index + 1].position
      )
    } else if (index === linksWithPosition.length - 1) {
      return active >= linksWithPosition[index].position
    }
  }

  return (
    <Menu>
      {linksWithPosition.map(l => (
        <li key={l.title}>
          <a
            href={l.link}
            onClick={e => handleScrollToElement(e, l.position)}
            style={{
              color: isActiveLink(l) ? '#F06449' : 'inherit',
              fontWeight: isActiveLink(l) ? '600' : '400',
            }}
          >
            {l.title}
          </a>
        </li>
      ))}
    </Menu>
  )
}

export default Scrolltabs
